<template>
  <div>
    <div v-if="!hasPaymentProviders">
      <span>{{
        $t('components.paymentMethod.noPaymentProvidersMessage')
      }}</span>
    </div>
    <div v-else>
      <div v-show="showWallet && stripeEnabled">
        <DividerWithText
          :text="$t('components.paymentMethod.prButtonUpperDivider')"
          for="prButton"
        />
        <div ref="prButton" class="my-5"></div>
        <div v-if="externalGPayAvailable" class="my-5">
          <button
            type="button"
            aria-label="Order with GPay"
            class="gpay-button black order en"
            @click="payWithExternalWallet"
          />
        </div>
        <DividerWithText
          :text="$t('components.paymentMethod.prButtonLowerDivider')"
          for="prButton"
        />
      </div>
      <v-radio-group
        v-model="otherPaymentMethod"
        column
        hide-details
        @change="onPaymentMethodChange"
      >
      <v-radio
          v-if="neopayBlikEnabled"
          :color="color"
          :value="providers.NEOPAY_BLIK"
          class="py-3"
          :ripple="false"
          data-cy="payment-method-neopay-blik"
        >
          <template v-slot:label>
            <div class="d-flex justify-space-between align-center w-100">
              <div>
                <div class="tp-text-subtitle-medium">
                  {{ $t('components.paymentMethod.blikTitle') }}
                </div>
                <div class="tp-text-label-2">
                  {{ $t('components.paymentMethod.blikDescription') }}
                </div>
              </div>
              <img :src="require(`@/assets/images/payments/blik-logo.svg`)" width="40" />
            </div>
          </template>
        </v-radio>
        <v-expand-transition>
          <v-row
            v-show="
              [providers.NEOPAY_BLIK].includes(
                otherPaymentMethod
              )
            "
            no-gutters
          >
            <v-col cols="12">
              <div class="tp-text-body-gray">
                {{ $t('components.paymentMethod.blikSubtitle') }}
              </div>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-radio
          v-if="montonioEnabled"
          :color="color"
          :value="providers.MONTONIO"
          class="py-3"
          :ripple="false"
          data-cy="payment-method-montonio"
        >
          <template v-slot:label>
            <div class="d-flex justify-space-between align-center w-100">
              <div class="d-flex justify-space-between align-center w-100">
                <div>
                  <div class="tp-text-subtitle-medium">
                    {{ $t('components.paymentMethod.banklinkTitle') }}
                  </div>
                  <div class="tp-text-label-2">
                    {{ $t('components.paymentMethod.banklinkDescription') }}
                  </div>
                </div>
                <img :src="require(`@/assets/images/icons/bank-icon.svg`)" />
              </div>
            </div>
          </template>
        </v-radio>
        <v-radio
          v-if="neopayEnabled"
          :color="color"
          :value="providers.NEOPAY"
          class="py-3"
          :ripple="false"
          data-cy="payment-method-neopay"
        >
          <template v-slot:label>
            <div class="d-flex justify-space-between align-center w-100">
              <div>
                <div class="tp-text-subtitle-medium">
                  {{ $t('components.paymentMethod.banklinkTitle') }}
                </div>
                <div class="tp-text-label-2">
                  {{ $t('components.paymentMethod.banklinkDescription') }}
                </div>
              </div>
              <img :src="require(`@/assets/images/icons/bank-icon.svg`)" />
            </div>
          </template>
        </v-radio>
        <v-expand-transition>
          <v-row
            v-show="
              [providers.MONTONIO, providers.NEOPAY].includes(
                otherPaymentMethod
              )
            "
            no-gutters
          >
            <v-col cols="12">
              <div class="tp-text-body-gray">
                {{ $t('components.paymentMethod.montonioSubtitle') }}
              </div>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-radio
          v-if="stripeEnabled"
          :value="providers.STRIPE"
          class="py-3"
          :ripple="false"
          data-cy="payment-method-stripe-card"
          :color="color"
        >
          <template v-slot:label>
            <div class="d-flex justify-space-between align-center w-100">
              <div>
                <div class="tp-text-subtitle-medium">
                  {{ $t('components.paymentMethod.cardTitle') }}
                </div>
                <div class="tp-text-label-2">
                  {{ $t('components.paymentMethod.cardDescription') }}
                </div>
              </div>
              <div class="d-flex">
                <img :src="require(`@/assets/images/payments/visa.svg`)" />
                <img
                  class="pl-1"
                  :src="require(`@/assets/images/payments/mastercard.svg`)"
                />
              </div>
            </div>
          </template>
        </v-radio>
        <v-expand-transition>
          <v-row v-show="otherPaymentMethod === providers.STRIPE" no-gutters>
            <v-col cols="12">
              <div ref="card" class="mb-3 mx-1"></div>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import DividerWithText from '@/components/common/DividerWithText.vue';
import { PROVIDERS } from '../../utils/constants';

export default {
  components: {
    DividerWithText,
  },
  props: {
    walletAvailable: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    paymentMethodsOverride: {
      type: Array,
      default: null,
    },
    clientSecret: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      otherPaymentMethod: null,
    };
  },
  computed: {
    providers() {
      return PROVIDERS;
    },
    hasPaymentProviders() {
      return this.$store.getters.hasPaymentProviders;
    },
    showWallet() {
      return this.walletAvailable || this.externalGPayAvailable;
    },
    stripeEnabled() {
      if (this.paymentMethodsOverride) {
        return this.paymentMethodsOverride.includes(this.providers.STRIPE);
      }
      return this.$store.getters.paymentProviderStripeEnabled;
    },
    montonioEnabled() {
      if (this.paymentMethodsOverride) {
        return this.paymentMethodsOverride.includes(this.providers.MONTONIO);
      }
      return this.$store.getters.paymentProviderMontonioEnabled;
    },
    neopayEnabled() {
      if (this.paymentMethodsOverride) {
        return this.paymentMethodsOverride.includes(this.providers.NEOPAY);
      }
      return this.$store.getters.paymentProviderNeopayEnabled;
    },
    neopayBlikEnabled() {
      if (this.paymentMethodsOverride) {
        return this.paymentMethodsOverride.includes(this.providers.NEOPAY_BLIK);
      }
      return this.$store.getters.paymentProviderNeopayBlikEnabled;
    },
    externalGPayAvailable() {
      return false;
      /**
       * Temporarily disable external google pay due to app compatibility issues
       */
      // return this.$store.getters.externalGPayWalletAvailable;
    },
  },
  methods: {
    onPaymentMethodChange() {
      this.$emit('on-change', this.otherPaymentMethod);
    },
    payWithExternalWallet() {
      // ASG INTEGRATION
      this.$store.dispatch('sendStripeClientSecret', this.clientSecret);
    },
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}

.gpay-button {
  background-origin: content-box;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0px;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 1px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
  height: 40px;
  min-height: 40px;
  padding: 12px 24px 10px;
  min-width: 240px;
  width: 100%;
}

.gpay-button.black {
  background-color: #000;
  box-shadow: none;
}

.gpay-button.black.order.en {
  background-image: url(https://www.gstatic.com/instantbuy/svg/dark/order/en.svg);
  min-width: 170px;
}
</style>
